body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    font-family: 'Tahoma' !important;
    padding: 10px 0 50px 0;
}
.main-app{
    max-width: 500px;
    margin: 0 auto;
}
.go-top-btn{
    cursor: pointer;
    float: right;
}

.card-main{
    margin-bottom: 10px;
}
.accordion-button{
    text-transform: capitalize;
}
.card-body{
    background-color: #f8f8f8;
}   
.card-text{
    direction: rtl;
}
.accordion-body{
    padding: 0.5rem;
}
.card-img-main{
    position: relative;
}
.card-img-span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    text-align: left;
    padding: 0.5rem;
    font-size: 1.5rem;
}
.card-img-top, .card-img-main{
    cursor: pointer;
}

.header-text{
    margin-bottom: 10px;
    direction: rtl;
}
.card-title{
    text-align: center;
}
