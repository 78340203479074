body{
    font-family: 'Tahoma' !important;
    padding: 10px 0 50px 0;
}
.main-app{
    max-width: 500px;
    margin: 0 auto;
}
.go-top-btn{
    cursor: pointer;
    float: right;
}
