.card-main{
    margin-bottom: 10px;
}
.accordion-button{
    text-transform: capitalize;
}
.card-body{
    background-color: #f8f8f8;
}   
.card-text{
    direction: rtl;
}
.accordion-body{
    padding: 0.5rem;
}
.card-img-main{
    position: relative;
}
.card-img-span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    text-align: left;
    padding: 0.5rem;
    font-size: 1.5rem;
}
.card-img-top, .card-img-main{
    cursor: pointer;
}
